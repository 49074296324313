import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../layout/BasicPage'
import DangerousHTML from '../../components/DangerousHTML'
import SEO from '../../components/SEO'

import './ResearchArticle.scss'

const ResearchArticle = ({ data, location }) => {
	const { article } = data

	return (
		<Layout width='narrow'>
			<SEO {...article.seo.page.metaData} href={location.href} />
			<div className='research-article'>
				<a href={article.data.frontmatter.url}>
					<h1>{article.data.frontmatter.title}</h1>
				</a>
				<span>
					{new Date(
						article.data.frontmatter.date
					).toLocaleDateString()}
				</span>
				<DangerousHTML>{article.data.html}</DangerousHTML>
			</div>
		</Layout>
	)
}

export default ResearchArticle

export const query = graphql`
	query ResearchArticle($relativePath: String) {
		article: file(relativePath: { eq: $relativePath }) {
			...SEOFragment
			data: childMarkdownRemark {
				frontmatter {
					title
					date
					url
					description
				}
				html
			}
		}
	}
`
